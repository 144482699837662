<template>
  <dir id="demandMarket">
    <h2>需求市场</h2>
    <div class="dm-body">
      <!-- 表格搜索 -->
      <div class="dma-condition-ss">
        <el-input v-model="searchValue" placeholder="请输入搜索内容"></el-input>
        <el-button type="primary" icon="el-icon-search" :loading="searchLoading" @click="currentPageChange(1)" style="margin-left: 10px">搜索</el-button>         
      </div>
      <!-- 表格 -->
      <div class="dm-table-box">
        <!-- 表格主体 -->
        <div class="dm-table">
          <el-table :data="tableData" stripe style="width: 100%" @selection-change="getSelection" ref="qaQLTable">
            <el-table-column type="selection" width="55" align="center"></el-table-column>
            <el-table-column prop="user.name" label="发布者姓名" align="center"></el-table-column>
            <el-table-column prop="title" label="需求内容" align="center"></el-table-column>
            <el-table-column prop="created_at" label="发布时间" align="center" width="190"></el-table-column>
            <el-table-column fixed="right" label="操作" align="center" width="150">
              <template slot-scope="scope">
                <el-button @click="DelClick(scope.row, scope.$index)" plain type="warning  " class="btn-bg-weight" size="small">删除</el-button>
                <el-button @click="hideClick(scope.row, scope.$index)" plain type="info" class="btn-bg-weight" size="small">{{scope.row.is_show == 1?'显示':'隐藏'}}</el-button>
              </template>            
            </el-table-column>
          </el-table>
        </div>
        <div class="pagings">
            <el-button class="Pagination-table-btn btn-bg-weight" type="warning" size="medium" plain @click="hideData(selectID)">隐藏选中数据</el-button>
            <Page
              @currentPageChange="currentPageChange"
              :pageSize="meta.per_page"
              :page="meta.current_page"
              :total="meta.total"
            />                        
        </div>        
      </div>
    </div>
  </dir>
</template>
<script>
import Page from '@/components/PageComponents'

export default {
  name: "demandMarket",
  data(){
    return {
      appId: localStorage.a_id,
      rangeValue: 1,//设置展示范围
      searchValue: '',
      demandOptions: [],
      tableData: [],
      selectID: [], //被选中的id
      hideIDList: [], //需要隐藏的id
      searchLoading: false, //搜索loading
      page: '',
      meta: {},
    }
  },
  created() {
    this.getTableData ()
  },
  components: {
    Page
  },  
  methods: {
    // 表格多选(隐藏)
    getSelection(val) {
      this.selectID = []
      for(let i = 0;i<val.length;i++){
        this.selectID.push(val[i].id);
      }
      console.log(this.selectID)
    },
    //多行列表隐藏
    hideData() {
      if (this.selectID.length === 0) {
        this.$message.error('请选择需要隐藏的需求');
      } else {
        let obj = {
          app_id: this.appId,
          ids: this.selectID,
          scene: 'hidden'
        }
        this.$superHttp.
          post(this.URL.superUrl.demandShow, obj).then(r => {
          this.$message.success('隐藏成功');
          this.getTableData()
        })
      }
    },
    // 单个隐藏
    hideClick(row, index) {
      this.$confirm(`是否${row.is_show == 1?'隐藏':'显示'}该需求?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let obj = {
          ids: [row.id],
          scene: row.is_show == 1? 'hidden' : 'show'
        }
        this.$superHttp.
          post(this.URL.superUrl.demandShow, obj).then(r => {
          this.$message.success('设置成功');
          this.getTableData()
        })        
      }).catch(() => { 
      });
    },
    // 删除
    DelClick(row, index) {
      console.log(row.id)
      this.$confirm(`是否删除该需求?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$superHttp
          .delete(this.URL.superUrl.demandDel + row.id).then(r => {
          this.$message.success('删除成功');
          this.getTableData()
        })        
      }).catch(() => { 
      });
    },
    // 页面跳转
    currentPageChange(p) {
      this.page = p
      this.getTableData()
    },
    // 获取表格数据
    getTableData (){
      this.searchLoading = true
      this.$superHttp
        .get(this.URL.superUrl.demandList,{params:{
          keyword: this.searchValue,
          page: this.page
        }})
        .then(res => {
          this.searchLoading = false
          this.tableData = res.data.data.data
          this.meta = res.data.data.meta        
        })
        .catch(err => {
          this.searchLoading = false
          console.log(err);
        });
    }
  },
}
</script>
<style lang="less" scoped>
  #demandMarket {
    padding: 0px;
    // 筛选
    .dma-condition-ss{
      box-sizing: border-box;
      position: relative;
      margin-top: 20px;
      .el-input {
        width: 70%;
      }
      .el-badge {
        position: absolute;
        right: 17px;
      }
    }
    // 表格搜索
    .dm-table-box{
      width: 100%;
      border: 1px solid #f2f5f5;
      border-radius: 10px;
      padding: 5px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      margin-top: 30px;      
      .dm-table-search {
        padding: 18px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border-bottom: 1px solid #f2f5f5;
        .el-input {
          width: 400px;
        }
        span {
          display: inline-block;
          padding: 4px 10px;
          background: #409EFF;
          margin-left: 20px;
          color: #fff;
          border-radius: 5px;
          cursor: pointer;
        }
        .el-button{
          width: 90px;
          height: 40px;
          font-weight: 400;
        }
      }
      // 分页
      .pagings{
        display: flex;align-items: center;
        margin: 20px 0 50px 0;
        .Pagination-table-btn{
          margin-right: 20px;
        }  
      }
    } 
  }
</style>